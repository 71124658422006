// store/slices/categorySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCategoryDataApi } from '../../services/api_calls/auth/AuthApi';

const initialState = {
    categories: [],
    loading: false,
    error: null,
    totalCount: 0
};

export const fetchCategories = createAsyncThunk(
    'categories/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getCategoryDataApi();

            if (!response?.categories || !Array.isArray(response.categories)) {
                return rejectWithValue('Invalid data structure received');
            }

            return {
                data: response.categories,
                total: response.categories.length
            };
        } catch (error) {
            console.error('Fetch Categories Error:', error);
            return rejectWithValue(
                error.response?.data?.message || 'Failed to fetch categories'
            );
        }
    }
);

const categorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = action.payload.data;
                state.totalCount = action.payload.total;
                state.error = null;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch categories';
            });
    }
});

export const { clearError } = categorySlice.actions;
export default categorySlice.reducer;